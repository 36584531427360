<template>
  <BDropdown
    @hide="handleHideMenu"
    ref="dropdown">

    <template #button-content>
      <span>
        <font-awesome-icon icon="clock" class="mr-1" />
        {{ value != null ? value.text : 'Select a duration' }}
      </span>
    </template>

    <BDropdownItemButton
      v-for="option in options"
      :key="option.text"
      @click="handleOptionClick(option)">
      {{ option.text }}
    </BDropdownItemButton>

    <BDropdownDivider />

    <BDropdownText
      text-class="font-weight-normal pl-3">
      Custom Duration
    </BDropdownText>

    <BDropdownForm form-class="px-2 py-2">
      <FlatPickr
        class="msi-date msi-date-sm date-picker mb-2"
        v-model="from"
        :config="fromDateConfig"
        placeholder="Start Date"
        @on-open="handleDatePickerStatus(true)"
        @on-close="handleDatePickerStatus(false)" />

      <FlatPickr
        class="msi-date msi-date-sm date-picker mb-2"
        v-model="to"
        :config="toDateConfig"
        placeholder="End Date"
        @on-open="handleDatePickerStatus(true)"
        @on-close="handleDatePickerStatus(false)" />

      <BButton
        variant="primary"
        size="sm"
        :disabled="applyCustomDurationButtonDisabled"
        @click="handleApplyCustomDuration">
        Apply
      </BButton>
    </BDropdownForm>
  </BDropdown>
</template>

<script>
import { BDropdown, BDropdownItemButton, BDropdownDivider, BDropdownText, BDropdownForm, BButton } from 'bootstrap-vue';
import FlatPickr from 'vue-flatpickr-component';

export default {
  name: 'DurationDropdown',
  components: {
    BDropdown,
    BDropdownItemButton,
    BDropdownDivider,
    BDropdownText,
    BDropdownForm,
    BButton,
    FlatPickr
  },
  props: {
    value: {
      type: Object
    },
    options: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      from: null,
      to: null,
      isDatePickerOpen: false
    };
  },
  computed: {
    applyCustomDurationButtonDisabled() {
      if (!this.from || !this.to) return true;
      return false;
    },
    fromDateConfig() {
      return { maxDate: this.to, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    },
    toDateConfig() {
      return { minDate: this.from, dateFormat: 'Y-m-d', disableMobile: true, allowInput: true };
    },
  },
  methods: {
    handleOptionClick(option) {
      this.$emit('input', option);
    },
    handleDatePickerStatus(open) {
      this.isDatePickerOpen = open;
    },
    handleHideMenu(event) {
      if (!this.isDatePickerOpen) return;
      event.preventDefault();
    },
    handleApplyCustomDuration() {
      const { from, to } = this;
      this.$emit('input', {
        text: `${from} to ${to}`,
        value: { from, to }
      });

      this.$refs.dropdown.hide(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker {
  width: 200px;
}
</style>
