<template>
  <BFormCheckbox
    :style="cssVars"
    :class="cssClass"
    switch
    v-bind="$attrs"
    v-on="$listeners">

    <slot></slot>

  </BFormCheckbox>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import chroma from 'chroma-js';

export default {
  name: 'BaseSwitch',
  components: {
    BFormCheckbox
  },
  props: {
    color: {
      type: String
    }
  },
  computed: {
    cssClass() {
      if (this.$attrs && this.$attrs.variant) return '';
      return 'base-switch';
    },
    cssVars() {
      if (!this.color) return {};
      return {
        '--base-switch-color': chroma(this.color).rgb().join(', ')
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.base-switch::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background: rgba(var(--base-switch-color), 1);
  border-color: rgba(var(--base-switch-color), 1);
}

.base-switch::v-deep .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(var(--base-switch-color), 0.25);
}

.base-switch::v-deep .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: rgba(var(--base-switch-color), 0.75);
}

.base-switch::v-deep .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background: rgba(var(--base-switch-color), 0.25);
  border-color: rgba(var(--base-switch-color), 0.1);
}
</style>
