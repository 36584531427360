function subVectors(a, b, target) {
  target[0] = a[0] - b[0];
  target[1] = a[1] - b[1];
  return target;
}

function dotVectors(a, b) {
  return (a[0] * b[0]) + (a[1] * b[1]);
}

const v0 = [0, 0];
const v1 = [0, 0];
const v2 = [0, 0];

function barycentric(point, a, b, c, target) {
  subVectors(c, a, v0);
  subVectors(b, a, v1);
  subVectors(point, a, v2);

  const dot00 = dotVectors(v0, v0);
  const dot01 = dotVectors(v0, v1);
  const dot02 = dotVectors(v0, v2);
  const dot11 = dotVectors(v1, v1);
  const dot12 = dotVectors(v1, v2);

  const denom = (dot00 * dot11 - dot01 * dot01);

  // collinear or singular triangle
  if (denom === 0) {
    // arbitrary location outside of triangle?
    // not sure if this is the best idea, maybe should be returning undefined
    target[0] = -2;
    target[1] = -1;
    return target;
  }

  const invDenom = 1 / denom;
  const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
  const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

  target[0] = 1 - u - v;
  target[1] = v;
  target[2] = u;
  return target;
}

export default barycentric;
