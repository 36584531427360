<template>
  <div>
    <LMap
      ref="map"
      :bounds="bounds"
      :padding="padding"
      :max-zoom="24"
      :options="mapOptions"
      @mousemove="handleMouseMove"
      @mouseout="removeTooltip"
      @zoomstart="tooltipDisabled = true"
      @zoomend="tooltipDisabled = false">

      <LTileLayer
        v-if="bounds"
        :url="mapboxUrl"
        :attribution="mapboxAttribution"
        :options="tileLayerOptions">
      </LTileLayer>

      <LImageOverlay
        v-if="heatmapUrl"
        :url="heatmapUrl"
        :bounds="bounds"
        interactive />

      <template v-if="!showLoader">
        <LMarker
          v-for="point in points"
          :key="`${point.coords.lat}_${point.coords.lng}`"
          :lat-lng="point.coords"
        />
      </template>

      <div v-show="showLoader">
        <div class="leaflet-control-loader d-flex align-items-center justify-content-center">
          <MsiSpinner :size="60" />
        </div>
      </div>

      <LControl position="bottomleft">
        <BButton aria-label="Home icon" @click="handleHome" :disabled="showLoader">
          <i class="icon-home" />
        </BButton>
      </LControl>

      <LControl position="topright">
        <Gradient
          v-if="gradient"
          :gradientColors="gradientColors"
          :types="gradient"
          :unit="gradientOptions.unit"
          size="sm"
          :style="{ fontSize: '15px' }" />
      </LControl>
    </LMap>

    <canvas
      ref="canvas"
      class="heatmap-canvas">
    </canvas>

    <div class="heatmap-message" v-if="!showLoader && !points.length">
      {{ emptyMessage }}
    </div>

    <div class="heatmap-message" v-if="!showLoader && error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable import/no-webpack-loader-syntax */
import { LMap, LTileLayer, LImageOverlay, LControl, LMarker } from 'vue2-leaflet';
import Delaunator from 'delaunator';
import lineToPolygon from '@turf/line-to-polygon';
import { multiLineString, featureCollection } from '@turf/helpers';
import bbox from '@turf/bbox';
import * as Comlink from 'comlink';
import Rbush from 'rbush';
import round from 'lodash/round';
import { BButton } from 'bootstrap-vue';
import 'leaflet/dist/leaflet.css';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import barycentric from '@/helpers/utils';
import MsiSpinner from '@/components/MsiSpinner.vue';
import Gradient from '@/components/leaflet/Gradient.vue';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const MAX_SIZE = 1024;

function _triangulatePoints(points) {
  const lngLatPoints = points.map(p => [p.coords.lng, p.coords.lat]);
  const { triangles } = Delaunator.from(lngLatPoints);

  const triangleArray = [];
  for (let i = 0; i < triangles.length; i += 3) {
    triangleArray.push({
      weights: [
        points[triangles[i]].weight,
        points[triangles[i + 1]].weight,
        points[triangles[i + 2]].weight
      ],
      points: [
        lngLatPoints[triangles[i]],
        lngLatPoints[triangles[i + 1]],
        lngLatPoints[triangles[i + 2]]
      ]
    });
  }

  return triangleArray;
}

function _getWeight(tree, lng, lat) {
  const results = tree.search({ minX: lng, minY: lat, maxX: lng, maxY: lat });
  const resultsWithRatio = results.map(r => ({ ...r, ratio: barycentric([lng, lat], ...r.triangle.points, [0, 0, 0]) }));
  const validResult = resultsWithRatio.find(r => r.ratio[0] >= 0 && r.ratio[1] >= 0 && r.ratio[2] >= 0);
  if (!validResult) return null;
  return validResult.ratio.reduce((acc, cur, i) => acc + cur * validResult.triangle.weights[i], 0);
}

export default {
  name: 'Heatmap',
  components: {
    LMap,
    LTileLayer,
    LImageOverlay,
    LMarker,
    MsiSpinner,
    LControl,
    BButton,
    Gradient
  },
  props: {
    points: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    emptyMessage: {
      type: String,
      default: 'No data to display'
    },
    errorMessage: {
      type: String,
      default: 'Unable to generate a heatmap'
    },
    tooltipFormatter: {
      type: Function
    },
    gradientOptions: {
      type: Object
    },
    showMarkers: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showLoader() {
      return this.generationInProgress || this.loading;
    },
    gradientColors() {
      if (this.gradient && this.gradient[this.gradientOptions.name]) {
        const { min, max } = this.gradient[this.gradientOptions.name];
        if (min === max) return ['yellow', 'yellow'];
      }

      return ['yellow', 'red'];
    }
  },
  data() {
    const mapboxToken = process.env.VUE_APP_MAPBOX_API_KEY;
    const mapboxUrl = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`;
    const mapboxAttribution = `<a href="https://www.mapbox.com/about/maps/"
      target="_blank" rel="noreferrer noopener">&copy; Mapbox &copy; OpenStreetMap</a> <a class="mapbox-improve-map"
      href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noreferrer noopener">Improve this map</a>`;

    const mapOptions = {
      zoomControl: false,
      zoomDelta: 0.5,
      zoomSnap: 0.1,
      wheelPxPerZoomLevel: 240,
      doubleClickZoom: false,
      gestureHandling: this.$feature.mobile
    };

    const tileLayerOptions = { maxZoom: 24, maxNativeZoom: 22 };

    return {
      heatmapUrl: null,
      error: false,
      bounds: null,
      padding: [50, 50],
      generationInProgress: false,
      tooltipDisabled: false,
      gradient: null,
      mapboxUrl,
      mapboxAttribution,
      mapOptions,
      tileLayerOptions
    };
  },
  methods: {
    removeTooltip() {
      if (this.$options.tooltip) {
        this.$options.tooltip.remove();
        this.$options.tooltip = null;
      }
    },
    handleMouseMove(event) {
      this.removeTooltip();
      if (this.$options.tree && this.$refs.map && !this.generationInProgress && !this.tooltipDisabled) {
        const weight = _getWeight(this.$options.tree, event.latlng.lng, event.latlng.lat);
        if (weight != null) {
          const textContent = this.tooltipFormatter ? this.tooltipFormatter(round(weight, 1)) : round(weight, 1);
          const text = document.createTextNode(textContent);
          this.$options.tooltip = L.tooltip()
            .setLatLng(event.latlng)
            .setContent(text)
            .addTo(this.$refs.map.mapObject);
        }
      }
    },
    async generateHeatmap() {
      this.error = false;

      if (!this.points.length) {
        this.clearHeatmap();
        return;
      }

      this.generationInProgress = true;

      const triangles = _triangulatePoints(this.points);
      if (!triangles.length) {
        this.error = true;
        this.generationInProgress = false;
        this.clearHeatmap();
        return;
      }

      const polygons = triangles.map(triangle => lineToPolygon(multiLineString([triangle.points])));
      const bboxes = polygons.map(p => bbox(p));

      const tree = new Rbush();
      this.$options.tree = tree;
      bboxes.forEach((box, index) => tree.insert({ minX: box[0], minY: box[1], maxX: box[2], maxY: box[3], triangle: triangles[index] }));

      const boundingBox = bbox(featureCollection(polygons));
      const bounds = [[boundingBox[1], boundingBox[0]], [boundingBox[3], boundingBox[2]]];
      this.bounds = bounds;

      const [minLat, minLng] = bounds[0];
      const [maxLat, maxLng] = bounds[1];
      const heightWidthRatio = (maxLat - minLat) / (maxLng - minLng);
      let height = MAX_SIZE;
      let width = MAX_SIZE;
      if (heightWidthRatio >= 1) width = Math.round(MAX_SIZE / heightWidthRatio);
      else height = Math.round(MAX_SIZE * heightWidthRatio);

      const weights = this.points.map(p => p.weight);
      let range = [Math.min(...weights), Math.max(...weights)];

      if (this.gradientOptions) {
        if (this.gradientOptions.range) range = this.gradientOptions.range;
        this.gradient = { [this.gradientOptions.name]: { min: range[0], max: range[1] } };
      }

      const heatmapWorker = Comlink.wrap(new Worker('@/workers/heatmap.worker.js', { type: 'module' }));
      const arr = await heatmapWorker(height, width, bounds, triangles, bboxes, range);

      const { canvas } = this.$refs;
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      const imageData = new ImageData(arr, width, height);
      const bitmap = await createImageBitmap(imageData);
      ctx.drawImage(bitmap, 0, 0, width, height);

      canvas.toBlob((blob) => {
        this.heatmapUrl = URL.createObjectURL(blob, { type: 'image/png' });
        this.generationInProgress = false;
      });
    },
    clearHeatmap() {
      this.heatmapUrl = null;
      this.bounds = null;
      this.$options.tree = null;
      this.gradient = null;
    },
    handleHome() {
      if (this.$refs.map.mapObject && this.bounds) {
        this.$refs.map.mapObject.flyToBounds(this.bounds, { padding: this.padding });
      }
    }
  },
  watch: {
    points() {
      this.clearHeatmap();
      this.generateHeatmap();
    }
  },
  mounted() {
    this.generateHeatmap();
  }
};
</script>

<style lang="scss" scoped>
.heatmap-canvas {
  display: none;
}

.leaflet-control-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -50px;
  height: 110px;
  width: 130px;
  border-radius: 10px;
  background: rgb(255, 255, 255);
  z-index: 1000;
}

.heatmap-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
  font-weight: 700;
  color: #666;
  user-select: none;
}
</style>
