<template>
  <BContainer fluid class="soiling-dashboard-container d-flex flex-column m-0 px-2 px-md-3 pt-2 pt-md-3">
    <div class="d-flex justify-content-end pb-2">
      <DurationDropdown
        v-model="duration"
        :options="durationOptions"
        class="duration-dropdown"
      />
    </div>

    <div class="soiling-stats d-flex flex-row justify-content-center flex-wrap mb-2 mb-sm-3">
      <StatisticWidget
        class="soiling-stats-item"
        title="Soiling Loss"
        subtitle="Last Week"
        :content="soilingCards.soilingLoss"
        :loading="loading"
        infoContent="Gross Revenue Losses due to soiling"
      />

      <StatisticWidget
        class="soiling-stats-item"
        title="Soiling Loss Rate"
        subtitle="Last Week"
        :content="soilingCards.soilingLossRate"
        :loading="loading"
        infoContent="Daily increase of soiling loss"
      />

      <StatisticWidget
        class="soiling-stats-item"
        title="Soiling Loss"
        subtitle="Last Week"
        :content="soilingCards.soilingLossMonetary"
        :loading="loading"
        infoContent="7 day rolling average of Net Revenue Losses"
      />

      <StatisticWidget
        class="soiling-stats-item"
        title="Projected Loss"
        subtitle="Next 7 Days"
        :content="soilingCards.soilingLossProjectedMonetary"
        :loading="loading"
        infoContent="Projected loss over next 7 days based on trend and previous weeks losses"
      />

      <StatisticWidget
        class="soiling-stats-item"
        title="Total Loss"
        :subtitle="dateRange ? `${dateRange.from} to ${dateRange.to}` : ''"
        :content="soilingCards.soilingLossTotalMonetary"
        :loading="loading"
        infoContent="Total Net Revenue Losses during the selected period"
      />
    </div>

    <div class="flex-grow-1 d-flex flex-column min-height-fit pb-2 pb-md-3">
      <BCard
        class="flex-grow-1 d-flex flex-column mb-0 min-height-fit overflow-hidden"
        body-class="flex-grow-1 d-flex flex-column min-height-fit p-0">

        <BRow class="flex-grow-1 min-height-fit">
          <BCol cols="12" xl="5" class="flex-grow-1 d-flex flex-column min-height-fit">
            <Heatmap
              class="flex-grow-1 soiling-heatmap"
              :points="soilingHeatmap"
              :loading="loading"
              :tooltipFormatter="value => `Soiling Loss: ${value}%`"
              :emptyMessage="heatmapEmptyMessage"
              errorMessage="Not enough data points to generate a heatmap"
              :gradientOptions="{ name: 'Avg. Soiling Loss', unit: '%', range: [0, 10] }" />
          </BCol>

          <BCol cols="12" xl="7" class="flex-grow-1 d-flex flex-column min-height-fit">
            <SoilingGraph
              class="px-3 py-3 px-xl-0"
              :data="soilingGraphData"
              :loading="loading"
              :timezone="timezone" />
          </BCol>
        </BRow>
      </BCard>
    </div>
  </BContainer>
</template>

<script>
import { BContainer, BRow, BCol, BCard } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import moment from 'moment-timezone';
import mean from 'lodash/mean';
import round from 'lodash/round';

import Heatmap from '@/components/leaflet/Heatmap.vue';
import StatisticWidget from '@/components/ui/StatisticWidget.vue';
import SoilingGraph from '@/components/graphs/soiling/SoilingGraph.vue';
import DurationDropdown from '@/components/ui/DurationDropdown.vue';
import { convertDurationtoFromTo, numberWithCommas } from '@/helpers/helpers';

function calculateWeekSoilingLossStats(soilingData) {
  const maxTimeDiffMs = 7 * 8.64e+7; // Past 7 days
  const now = Date.now();

  const points = [];
  let prevPoint = null;
  let i = soilingData.length - 1;
  while (i >= 0) {
    const point = soilingData[i];
    const isPointInWeek = now - new Date(point.timestamp) < maxTimeDiffMs;
    if (isPointInWeek && !prevPoint) points.push(point);
    else if (isPointInWeek && prevPoint && prevPoint.soilingRate === point.soilingRate) points.push(point);
    else break;

    prevPoint = point;
    i--;
  }

  if (!points.length) return null;
  const soilingLoss = mean(points.map(p => p.revenueWithoutSoiling - p.revenue));
  const rate = points[0].soilingRate;
  const soilingLossProjected = (new Array(7)).fill(0).map((p, idx) => soilingLoss * (1 + rate * idx)).reduce((acc, cur) => acc + cur, 0);
  return { soilingLossMonetary: soilingLoss, soilingLossProjectedMonetary: soilingLossProjected };
}

export default {
  name: 'SoilingDashboard',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    Heatmap,
    StatisticWidget,
    SoilingGraph,
    DurationDropdown
  },
  data() {
    const durationOptions = [
      { text: 'Last Month', value: moment.duration(1, 'month').asMilliseconds() },
      { text: 'Last 3 Months', value: moment.duration(3, 'month').asMilliseconds() },
      { text: 'Last  6 Months', value: moment.duration(6, 'month').asMilliseconds() },
      { text: 'Last Year', value: moment.duration(1, 'year').asMilliseconds() }
    ];

    return {
      duration: durationOptions[0],
      durationOptions,
      rawSoilingData: [],
      loading: true
    };
  },
  computed: {
    selectedSite: get('sites/selectedSite'),
    getSiteSolarModules: get('solarmodules/getSiteSolarModules'),
    timezone() {
      return this.selectedSite ? this.selectedSite.timezone : 'UTC';
    },
    solarModules() {
      if (!this.selectedSite) return [];
      return this.getSiteSolarModules(this.selectedSite.id).filter(m => m.coords);
    },
    solarModulesMap() {
      return this.solarModules.reduce((acc, cur) => {
        acc[cur.uuid] = cur;
        return acc;
      }, {});
    },
    dateRange() {
      if (!this.selectedSite || !this.duration) return null;
      return convertDurationtoFromTo(this.duration.value, this.selectedSite.timezone);
    },
    soilingData() {
      if (!this.selectedSite || !this.duration) return [];
      const { from, to } = convertDurationtoFromTo(this.duration.value, this.selectedSite.timezone);
      const fromMoment = moment.tz(moment(from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone);
      const toMoment = moment.tz(moment(to).endOf('day').format('YYYY-MM-DD HH:mm:ss'), this.selectedSite.timezone);
      return this.rawSoilingData
        .map(d => ({ ...d, timestamp: new Date(d.timestamp) }))
        .filter(d => d.timestamp >= fromMoment && d.timestamp <= toMoment);
    },
    soilingHeatmap() {
      const moduleSoiling = this.soilingData.reduce((acc, cur) => {
        Object.keys(cur.moduleSoilingLevel).forEach((uuid) => {
          if (cur.moduleSoilingLevel[uuid] != null) {
            if (!acc[uuid]) acc[uuid] = { sum: 0, num: 0 };
            acc[uuid].sum += cur.moduleSoilingLevel[uuid];
            acc[uuid].num += 1;
          }
        });

        return acc;
      }, {});

      return Object.keys(moduleSoiling).reduce((acc, cur) => {
        if (this.solarModulesMap[cur]) {
          acc.push({ coords: this.solarModulesMap[cur].coords, weight: (1 - Math.min(moduleSoiling[cur].sum / moduleSoiling[cur].num, 1)) * 100 });
        }

        return acc;
      }, []);
    },
    soilingGraphData() {
      return this.soilingData.reduce((acc, cur, index) => {
        const timestamp = cur.timestamp.valueOf();
        acc.soiling.push({ x: timestamp, y: (1 - cur.soilingLevel) * 100, options: { soilingRate: cur.soilingRate } });
        const moduleSoiling = Object.values(cur.moduleSoilingLevel).filter(v => v != null).map(s => (1 - s) * 100);
        if (moduleSoiling.length) {
          acc.soilingRange.push([timestamp, Math.min(...moduleSoiling), Math.max(...moduleSoiling)]);
          acc.soilingAverage.push([timestamp, mean(moduleSoiling)]);
        }

        acc.energyLoss.push([timestamp, cur.energyWithoutSoiling - cur.energy]);
        acc.revenueLoss.push([timestamp, cur.revenueWithoutSoiling - cur.revenue]);

        const prevCumRevenue = acc.cumRevenue[index - 1] != null ? acc.cumRevenue[index - 1][1] : 0;
        const prevCumRevenueWithoutSoiling = acc.cumRevenueWithoutSoiling[index - 1] != null ? acc.cumRevenueWithoutSoiling[index - 1][1] : 0;
        acc.cumRevenue.push([timestamp, cur.revenue + prevCumRevenue]);
        acc.cumRevenueWithoutSoiling.push([timestamp, cur.revenueWithoutSoiling + prevCumRevenueWithoutSoiling]);
        return acc;
      }, {
        soiling: [],
        soilingRange: [],
        soilingAverage: [],
        energyLoss: [],
        revenueLoss: [],
        cumRevenue: [],
        cumRevenueWithoutSoiling: []
      });
    },
    soilingCards() {
      const maxTimeDiffMs = 7 * 8.64e+7; // Past 7 days
      const soilingCards = {
        soilingLoss: 'No Data',
        soilingLossRate: 'No Data',
        soilingLossMonetary: 'No Data',
        soilingLossProjectedMonetary: 'No Data',
        soilingLossTotalMonetary: 'No Data'
      };

      const lastPoint = this.rawSoilingData.length ? this.rawSoilingData[this.rawSoilingData.length - 1] : null;
      if (lastPoint && (Date.now() - new Date(lastPoint.timestamp)) < maxTimeDiffMs) {
        soilingCards.soilingLoss = lastPoint.soilingLevel <= 1 ? `${round((1 - lastPoint.soilingLevel) * 100, 2)}%` : '0%';
        soilingCards.soilingLossRate = `${round(lastPoint.soilingRate * 100, 2)}%/day`;
      }

      const stats = calculateWeekSoilingLossStats(this.rawSoilingData);
      if (stats) {
        soilingCards.soilingLossMonetary = `$${numberWithCommas(round(stats.soilingLossMonetary))}/day`;
        soilingCards.soilingLossProjectedMonetary = `$${numberWithCommas(round(stats.soilingLossProjectedMonetary))}`;
      }

      if (this.soilingData.length) {
        const soilingLossTotalMonetary = this.soilingData.reduce((acc, cur) => acc + (cur.revenueWithoutSoiling - cur.revenue), 0);
        soilingCards.soilingLossTotalMonetary = `$${numberWithCommas(round(soilingLossTotalMonetary))}`;
      }

      return soilingCards;
    },
    heatmapEmptyMessage() {
      if (!this.soilingData.length) return 'There is no soiling data to display';
      if (!this.solarModules.length) return 'The modules must have geographical coordinates';
      return '';
    }
  },
  methods: {
    async getSoilingData() {
      if (!this.selectedSite || this.$options.initialized || !this.$options.active) return;

      try {
        this.loading = true;
        const { timezone } = this.selectedSite;
        const from = moment.tz(moment(new Date('2000-01-01')), timezone).startOf('day').toISOString(true);
        const to = moment.tz(moment(new Date()), timezone).endOf('day').toISOString(true);
        this.rawSoilingData = await this.$daqApi.get(`/sites/${this.selectedSite.id}/soiling`, { query: { from, to } });
      } catch (e) {
        this.rawSoilingData = [];
        if (e.name === 'ApiError') this.$toastError(`Error ${e.status || ''}`, e.message);
        else throw e;
      } finally {
        this.loading = false;
        this.$options.initialized = true;
      }
    }
  },
  activated() {
    this.$options.active = true;
    this.getSoilingData();
  },
  deactivated() {
    this.$options.active = false;
  },
  watch: {
    selectedSite() {
      this.$options.initialized = false;
      this.rawSoilingData = [];
      this.getSoilingData();
    }
  }
};
</script>

<style lang="scss" scoped>
.soiling-dashboard-container {
  height: calc(100vh - 105px)
}

.soiling-stats {
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.soiling-stats-item {
  flex: 1 1 0;
  min-width: 310px;
  max-width: 100%;
}

@media only screen and (min-width: 576px) {
  .soiling-stats {
    column-gap: 1.5rem;
    row-gap: 1rem;
  }
  .soiling-stats-item {
    max-width: 310px;
  }
}

.min-height-fit {
  min-height: fit-content;
}

.soiling-card-title {
  font-size: 1.1rem;
}

.soiling-heatmap {
  min-height: 400px;
}

.duration-dropdown::v-deep .dropdown-toggle {
  background: white;
  font-size: 15px;
}

.duration-dropdown::v-deep .dropdown-toggle:hover, .duration-dropdown::v-deep .dropdown-toggle:active {
  background: #fafafa;
}
</style>
