<template>
  <div class="flex-grow-1 d-flex flex-column min-height-fit">
    <SoilingGraphControls
      v-model="soilingGraphControls"
      class="pb-3"
      :disabled="loading"
      :style="{ fontSize: '15px' }" />

    <div class="graph-container flex-grow-1 flex-shrink-1 d-flex flex-column">
      <HighChartsResponsive
        ref="graph"
        :options="highchartOptions"
        class="highcharts-container" />
    </div>

    <BFormCheckbox
      v-model="minZeroChecked"
      :style="{ fontSize: '15px' }">
      Lock axes to 0
    </BFormCheckbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import round from 'lodash/round';

import HighChartsResponsive, { timeDependentButtons } from '@/components/HighChartsResponsive.vue';
import SoilingGraphControls from '@/components/graphs/soiling/SoilingGraphControls.vue';
import { mapTypeToAxesData, parseEnergy } from '@/components/graphs/helpers';
import { numberWithCommas } from '@/helpers/helpers';

const graphFontSize = '14px';

export default {
  name: 'SoilingGraph',
  components: {
    BFormCheckbox,
    HighChartsResponsive,
    SoilingGraphControls
  },
  props: {
    data: Object,
    loading: Boolean,
    timezone: String
  },
  data() {
    return {
      soilingGraphControls: {
        soilingLossChecked: true,
        energyLossChecked: false,
        revenueLossChecked: false,
        cumRevenueLossChecked: false
      },
      minZeroChecked: true,
      highchartOptions: {
        chart: {
          zoomType: 'x',
          spacingBottom: 0
        },
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              fontSize: graphFontSize
            }
          },
          showEmpty: false,
          minRange: 900000,
          labels: {
            style: {
              fontSize: graphFontSize
            }
          }
        },
        yAxis: [],
        series: [],
        tooltip: {
          xDateFormat: '%A, %b %e',
          formatter: function formatter(tooltip) {
            const { type } = this.series.options.metaData;
            if (type === 'Energy') {
              const date = tooltip.defaultFormatter.call(this, tooltip)[0];
              return `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}:
              <span style="font-weight: bold;">${parseEnergy(this.point.y, 2)}</span>`;
            }

            if (type === 'Soiling Loss') {
              const date = tooltip.defaultFormatter.call(this, tooltip)[0];
              return `${date}<span style="color: ${this.color}">\u25CF</span> ${this.series.name}<br/>
              Loss: <span style="font-weight: bold;">${round(this.point.y, 2)} %</span><br/>
              Loss Rate: <span style="font-weight: bold;">${round(this.point.options.soilingRate * 100, 2)} %/day</span>`;
            }

            return tooltip.defaultFormatter.call(this, tooltip);
          },
          useHTML: true
        },
        legend: {
          padding: 20,
          maxHeight: 90,
          itemStyle: {
            fontSize: graphFontSize
          }
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 600
            },
            chartOptions: {
              legend: {
                maxHeight: 55
              }
            }
          }]
        },
        exporting: {
          filename: 'soiling-graph',
          buttons: {
            contextButton: {
              menuItems: timeDependentButtons
            }
          }
        }
      }
    };
  },
  methods: {
    showLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.showLoading();
      this.$emit('loading', true);
    },
    hideLoading() {
      if (this.$refs.graph) this.$refs.graph.chart.hideLoading();
      this.$emit('loading', false);
    },
    async plot() {
      const { soilingLossChecked, energyLossChecked, revenueLossChecked, cumRevenueLossChecked } = this.soilingGraphControls;

      const series = [];
      if (soilingLossChecked) series.push(...this.getSoilingLossSeries());
      if (energyLossChecked) series.push(this.getEnergyLossSeries());
      if (revenueLossChecked) series.push(this.getRevenueLossSeries());
      if (cumRevenueLossChecked) series.push(this.getCumRevenueLossSeries());

      const seriesWithData = series.filter(s => s && s.data.length);
      if (seriesWithData.length) {
        this.setupYaxis(seriesWithData);
        this.$set(this.highchartOptions, 'series', seriesWithData);
      } else {
        this.clearPlot();
      }

      if (this.$refs.graph && this.$refs.graph.chart && !this.highchartOptions.series.length) this.$refs.graph.chart.redraw();
    },
    clearPlot() {
      this.$set(this.highchartOptions, 'series', []);
      this.$set(this.highchartOptions, 'yAxis', []);
    },
    getSoilingLossSeries() {
      const metaData = { type: 'Soiling Loss', unit: '%' };
      const tooltip = { valueDecimals: 2 };
      return [
        { id: 'Soiling', name: 'Soiling Loss Trend', metaData, data: this.data.soiling, color: '#23282c', zIndex: 1, tooltip },
        {
          id: 'Soiling Avg',
          name: 'Soiling Loss Average',
          data: this.data.soilingAverage,
          color: '#883b0066',
          zIndex: 0,
          metaData: { unit: '%' },
          tooltip: { ...tooltip, valueSuffix: '%' }
        },
        {
          id: 'Soiling Range',
          name: 'Soiling Loss Range',
          data: this.data.soilingRange,
          type: 'arearange',
          lineWidth: 0,
          color: '#883B00',
          fillOpacity: 0.2,
          zIndex: 0,
          metaData,
          marker: {
            enabled: false
          },
          enableMouseTracking: false
        }
      ];
    },
    getEnergyLossSeries() {
      const metaData = { type: 'Energy' };
      return { id: 'Energy', name: 'Daily Energy Loss', metaData, data: this.data.energyLoss, color: '#F28F43' };
    },
    getRevenueLossSeries() {
      const metaData = { type: 'Revenue Loss', unit: '$' };
      const tooltip = { valueDecimals: 0, valuePrefix: '$' };
      return { id: 'Revenue Loss', name: 'Daily Revenue Loss', metaData, data: this.data.revenueLoss, color: '#1cb150', tooltip };
    },
    getCumRevenueLossSeries() {
      const metaData = { type: 'Revenue Loss', unit: '$' };
      const tooltip = { valueDecimals: 0, valuePrefix: '$' };
      const data = this.data.cumRevenueWithoutSoiling.map((d, i) => [d[0], d[1] - this.data.cumRevenue[i][1]]);
      return { id: 'Cum Revenue Loss', name: 'Cumulative Revenue Loss', metaData, data, color: '#8085e9', tooltip };
    },
    setupYaxis(series) {
      const yAxisMap = {};
      const yAxis = [];
      series.forEach((s) => {
        const axisType = s.metaData.unit != null ? s.metaData.unit : s.metaData.type;
        if (yAxisMap[axisType] == null) {
          yAxisMap[axisType] = yAxis.length;

          let axis = null;
          if (s.metaData.unit != null) {
            const format = `{value}${s.metaData.unit}`;
            const formatter = s.metaData.unit === '$' ? ({ value }) => `$${numberWithCommas(value)}` : null;
            axis = { labels: { format, formatter }, title: { text: s.metaData.type }, gridZIndex: -1, min: this.minZeroChecked ? 0 : null };
          } else {
            axis = { ...mapTypeToAxesData(axisType), min: this.minZeroChecked ? 0 : null };
            if (axisType === 'Energy') axis.title.text = 'Energy Loss';
          }

          axis.labels.style = { fontSize: graphFontSize };
          axis.title.style = { fontSize: graphFontSize };
          if (yAxis.length % 2 === 1) yAxis.push({ ...axis, opposite: true });
          else yAxis.push(axis);
        }

        s.yAxis = yAxisMap[axisType];
      });

      this.$set(this.highchartOptions, 'yAxis', yAxis);
    },
  },
  mounted() {
    if (this.loading) this.showLoading();
    else this.hideLoading();
  },
  watch: {
    soilingGraphControls: {
      immediate: true,
      handler() {
        this.plot();
      }
    },
    data: {
      immediate: true,
      handler() {
        this.plot();
      }
    },
    loading: {
      immediate: true,
      handler() {
        if (this.loading) this.showLoading();
        else this.hideLoading();
      }
    },
    timezone: {
      immediate: true,
      handler() {
        this.$set(this.highchartOptions, 'time', { timezone: this.timezone || 'UTC' });
      }
    },
    minZeroChecked() {
      this.plot();
    }
  }
};
</script>

<style lang="scss" scoped>
.highcharts-container {
  min-height: 0;
  min-width: 0;
}

.min-height-fit {
  min-height: fit-content;
}

.graph-container {
  flex-basis: 0;
  min-height: 400px;
}
</style>
