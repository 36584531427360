<template>
  <BCard
    no-body
    class="mb-0 d-flex flex-column pt-3 pb-2 px-4">

    <BSkeletonWrapper :loading="loading">
      <div class="content">{{ content }}</div>

      <template #loading>
        <BSkeleton height="34px" width="80%" />
      </template>
    </BSkeletonWrapper>

    <div class="d-flex align-items-center">
      <div class="title">{{ title }}</div>
      <InfoIcon
        class="ml-2"
        v-if="infoContent.length"
        :tooltipContent="infoContent"
        size="1x"
      />
    </div>

    <div
      v-if="subtitle"
      class="subtitle">
      {{ subtitle }}
    </div>
  </BCard>
</template>

<script>
import { BCard, BSkeleton, BSkeletonWrapper } from 'bootstrap-vue';
import InfoIcon from '@/components/ui/InfoIcon.vue';

export default {
  name: 'StatisticWiget',
  components: {
    BCard,
    BSkeleton,
    BSkeletonWrapper,
    InfoIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    infoContent: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.subtitle {
  color: #777;
}

.content {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 1px;
}
</style>
