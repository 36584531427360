<template>
  <div class="d-flex flex-row flex-wrap soiling-switch-group">
    <BaseSwitch
      :checked="localControls.soilingLossChecked"
      :disabled="disabled"
      @input="handleChecked('soilingLossChecked', $event)"
      color="#883B00">
      Soiling Loss
    </BaseSwitch>

    <BaseSwitch
      switch
      :checked="localControls.energyLossChecked"
      :disabled="disabled"
      @input="handleChecked('energyLossChecked', $event)"
      color="#F28F43">
      Daily Energy Loss
    </BaseSwitch>

    <BaseSwitch
      switch
      :checked="localControls.revenueLossChecked"
      :disabled="disabled"
      @input="handleChecked('revenueLossChecked', $event)"
      color="#1CB150">
      Daily Revenue Loss
    </BaseSwitch>

    <BaseSwitch
      switch
      :checked="localControls.cumRevenueLossChecked"
      :disabled="disabled"
      @input="handleChecked('cumRevenueLossChecked', $event)"
      color="#8085e9">
      Cumulative Revenue Loss
    </BaseSwitch>
  </div>
</template>

<script>
import BaseSwitch from '@/components/ui/BaseSwitch.vue';

const controls = {
  soilingLossChecked: false,
  energyLossChecked: false,
  revenueLossChecked: false,
  cumRevenueLossChecked: false
};

export default {
  name: 'SoilingGraphControls',
  components: {
    BaseSwitch
  },
  props: {
    value: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    localControls() {
      return { ...controls, ...this.value };
    }
  },
  methods: {
    handleChecked(key, checked) {
      this.$emit('input', { ...this.localControls, [key]: checked });
    }
  }
};
</script>

<style lang="scss" scoped>
.soiling-switch-group {
  gap: 1rem;
}
</style>
